/* eslint-disable react/no-unescaped-entities */
import { ChevronRightIcon } from '@heroicons/react/solid';
import Image from 'next/image';
import Link from 'next/link';

export default function HomeHeroTwo() {
  return (
    <div className='relative isolate overflow-hidden bg-white'>
      <div className='mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40'>
        <div className='mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8'>
          <h1 className='mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl'>Proxy Magic: The Gathering cards</h1>
          <p className='mt-6 text-lg leading-8 text-gray-600'>
            <span className='font-bold'>MTG Proxies</span> helps you proxy MTG cards to use in your decks <span className='font-bold'>completely free</span>!
          </p>
          <div className='mt-10 flex items-center gap-6 flex-col md:flex-row'>
            <Link href='/search' className='flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white bg-green-600 border border-transparent rounded-md hover:bg-green-700 md:py-4 md:text-lg md:px-10'>
              Search by card
            </Link>
            <Link href='/sets' className='flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white bg-green-600 border border-transparent rounded-md hover:bg-green-700 md:py-4 md:text-lg md:px-10'>
              Search by set
            </Link>
          </div>
        </div>
        <div className='mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32'>
          <div className='max-w-3xl flex-none sm:max-w-5xl lg:max-w-none'>
            <div className='-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4'>
              <Image src='/zombie-search.png' alt='search screenshot' width={3456} height={2010} className='w-[50rem] rounded-md shadow-2xl ring-1 ring-gray-900/10' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
