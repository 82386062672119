import Head from 'next/head';
import Cta from '../components/Cta';
import HomeFeatures from '../components/HomeFeatures';
import HomeHeroTwo from '../components/HomeHeroTwo';
import { PAGES } from '../config';

export default function Home() {
  return (
    <article>
      <Head>
        <title>{PAGES.HOME.title}</title>
        <meta name='description' content={PAGES.HOME.description} />

        <meta property='og:title' content={PAGES.HOME.title} />
        <meta property='og:description' content={PAGES.HOME.description} />

        <meta name='twitter:title' content={PAGES.HOME.title} />
        <meta name='twitter:description' content={PAGES.HOME.description} />
        <meta name='twitter:image' content={PAGES.HOME.twitter_image} />
        <meta name='twitter:card' content='summary_large_image' />
      </Head>

      <HomeHeroTwo />
      <HomeFeatures />
      <Cta />

      {/* <div className='max-w-2xl py-16 mx-auto lg:py-32'>
        <Container>
          <div className='space-y-3'>
            <h2 className='text-3xl font-bold text-48'>More features coming soon</h2>
            <p className='text-lg text-gray-600'>
              I&apos;ll be adding new features to the site in the next few weeks. If you have any feedback or questions or need some help, please reach out to{' '}
              <a href='mailto: hello.mtgproxies@gmail.com' className='font-semibold text-green-600'>
                hello.mtgproxies@gmail.com
              </a>
              .
            </p>
          </div>
        </Container>
      </div> */}
    </article>
  );
}
