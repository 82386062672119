import Link from 'next/link';

export default function Cta() {
  return (
    <div className='bg-white'>
      <div className='mx-auto max-w-7xl px-6 pt-10 pb-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8'>
        <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
          Ready to dive in?
          <br />
          <span className='text-green-600'>Start proxying cards today.</span>
        </h2>

        <div className='mt-10 flex items-center gap-6 lg:mt-0 lg:flex-shrink-0 flex-col md:flex-row'>
          <Link href='/search' className='flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white bg-green-600 border border-transparent rounded-md hover:bg-green-700 md:py-4 md:text-lg md:px-10 whitespace-nowrap'>
            Search by card
          </Link>
          <Link href='/sets' className='flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white bg-green-600 border border-transparent rounded-md hover:bg-green-700 md:py-4 md:text-lg md:px-10 whitespace-nowrap'>
            Search by set
          </Link>
        </div>
      </div>
    </div>
  );
}
