import Image from 'next/image';

const features = [
  {
    name: 'Add card to your print list',
    description: 'Search for any card using the Search page or browse by set and add the cards you want to your print list.',
  },
  {
    name: 'Print your cards',
    description: 'Once you have added all the cards you want to your print list, you can print them out and use them in your decks.',
  },
  {
    name: 'Use them in your decks',
    description: 'Once you have printed out your cards, you can use them in your decks. Cut them out and tape them to a basic land or use a sleeve with a basic land inside.',
  },
];

export default function HomeFeatures() {
  return (
    <div className='overflow-hidden bg-white py-24 sm:py-32'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2'>
          <div className='lg:ml-auto lg:pt-4'>
            <div className='lg:max-w-lg'>
              <h2 className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>Print MTG Proxy Cards</h2>
              <p className='mt-6 text-lg leading-8 text-gray-600'>Add cards to your print list, print them out, and use them in your decks today!</p>
              <dl className='mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none'>
                {features.map((feature) => (
                  <div key={feature.name} className='relative'>
                    <dt className='inline font-semibold text-gray-900'>{feature.name}</dt> <dd className='inline'>{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>

          <div className='flex items-start justify-end lg:order-first'>
            <Image src='/cart.png' alt='cart screenshot' className='w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]' width={1506} height={1374} />
          </div>
        </div>
      </div>
    </div>
  );
}
